<template>
  <div class="nav_bar">
    <div v-if="isCanterbury">
      <div style="height: 1rem; background-color: white" ></div>
    </div>
    <div class="container">
      <!-- logo -->
      <div class="d-flex flex-row">
        <img alt="logo" class="l-logo" src="../assets/image/logo.png">
<!--        <img v-if="isCanterbury && windowWidth >= 1200"
             alt="logo canterbury" class="l-logo-canterbury"
             style="align-self: end"
             src="../assets/image/logo_canterbury.png">-->
      </div>
      <!-- inputs -->
      <div class="l-inputs container mt-2 pe-4 nav_width">
        <div class="d-flex flex-row justify-content-end ms-5">
          <div class="col-4"
               :class="{
            'col-8': windowWidth <= 414
            }">
            <select class="form-select" aria-label="Select Age Group"
                    v-model="mAgeGroup.selected">
              <option v-for="(option, index) in mAgeGroup.options" :key="index"
                      :value="option.value">
                {{option.text}}
              </option>
            </select>
          </div>
          <div class="col-2 ms-1"
               :class="{
            'col-4': windowWidth <= 414
            }">
            <select class="form-select" aria-label="Select Year"
                    v-model="mYear.selected">
              <option v-for="(option, index) in mYear.options" :key="index"
                      :value="option.value">{{option.text}}</option>
            </select>
          </div>
        </div>
        <div class="d-flex flex-row justify-content-end gap-1 mt-2 mb-2">
          <div class="mt-2 pt-2">
            <input type="radio"
                   :name="mGender.options[0].value"
                   :id="mGender.options[0].value"
                   :value="mGender.options[0].value"
                   v-model="mGender.selected">
            <label class="ms-1 fw-bold" :for="mGender.options[0].value">{{mGender.options[0].text}}</label>

            <input class="ms-3" type="radio"
                   :name="mGender.options[1].value"
                   :id="mGender.options[1].value"
                   :value="mGender.options[1].value"
                   v-model="mGender.selected">
            <label class="ms-1 fw-bold" :for="mGender.options[1].value">{{mGender.options[1].text}}</label>
          </div>
          <button class="btn btn-secondary col-2 ms-4"
                  :class="{
            'col-4': windowWidth <= 414
            }"
                  style="font-weight: bold" type="button" @click="onQueryXlr8">View</button>

        </div>
      </div>
    </div>
    <div v-if="isCanterbury">
      <div style="height: 0.5rem; background-color: white" ></div>
      <div style="height: 6px; background-color: var(--background-canterbury-black)" ></div>
      <div style="height: 1px; background-color: white" ></div>
    </div>
  </div>
</template>

<script>

import appBloc from '@/bloc/app_bloc';
import logger from "@/util/logger";
const LOG_TAG = 'nav_bar';

export default {
  name: LOG_TAG,
  props: {

  },
  data() {
    return {
      mAgeGroup: {
        selected: 'select',
        options: [
          {text: 'Select Age Group', value: 'select'},
          {text: '10 years / under', value: 10},
          {text: '11 years', value: 11},
          {text: '12 years', value: 12},
          //{text: '13 / 14 years', value: 13},
        ],
        getSelectedOption() {
          return this.options.find((option) => option.value == this.selected);
        }
      },
      mYear: {
        selected: null,
        options: [
          {text: '2025', value: 2025},
          {text: '2024', value: 2024},
          {text: '2023', value: 2023},
          {text: '2022', value: 2022},
        ],
        init() {
          this.selected = this.options[0].value;
        },
        getSelectedOption() {
          return this.options.find((option) => option.value == this.selected);
        }
      },
      mGender: {
        selected: null,
        options: [
          {text: 'Male', value: 'Male'},
          {text: 'Female', value: 'Female'},
        ],
        init() {
          this.selected = this.options[0].value;
        },
      }
    };
  },
  watch: {


  },
  computed: {
    windowWidth() {
      return this.$store.state.windowWidth;
    },
    isCanterbury() {
      return process.env.VUE_APP_BRAND === 'canterbury';
    }
  },
  mounted() {
    const vm = this;
    vm.mYear.init();
    vm.mGender.init();
  },
  methods: {
    onQueryXlr8() {
      const vm = this;
      logger.d(LOG_TAG, 'onQueryXlr8', vm.mAgeGroup.selected, vm.mGender.selected, vm.mYear.selected);
      if (vm.mAgeGroup.selected !== 'select' && vm.mGender.selected && vm.mYear.selected) {
        window.store.commit('setXlr8AgeGroup', vm.mAgeGroup.getSelectedOption());
        window.store.commit('setXlr8Year', vm.mYear.getSelectedOption());
        window.store.commit('setXlr8Results', []);
        window.store.commit('setLoading', true);
        appBloc.queryXlr8(
            vm.mAgeGroup.selected,
            vm.mGender.selected,
            vm.mYear.selected,
            () => {
              window.store.commit('setLoading', false);
            },
            () => {
              window.store.commit('setLoading', false);
            }
        );
      }
    },
    onNotifyGenderChanged() {
      const vm = this;
      logger.d(LOG_TAG, 'onNotifyGenderChanged', vm.mGender.selected);
    },
  }
}
</script>
<style scoped>
.nav_bar {
  position: sticky;
  top: 0;
  height: 10rem;
  background-color: white;
  z-index: 2;
}
.l-logo {
  height: 9rem;
}
.l-logo-canterbury {
  height: 5rem;
  margin-left: 1rem;
}
.l-inputs {
  position: absolute;
  bottom: 0;
}
.v-center {
  position: absolute;
  width: 100%;
  top: 25%;
}
@media screen and (max-width: 512px) {
  .l-logo {
    display: none;
  }
  .nav_width {
    width: 85%;
  }
}
</style>
